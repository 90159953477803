import React, { FC } from "react";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

import DefaultImage from "../img/news/share/image_001.png";

type Props = {
  data: {
    allMarkdownRemark: {
      edges: Node[];
      totalCount: number;
    };
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
  pageContext: any;
};

type Node = {
  node: {
    fields: {
      slug: string;
    };
    frontmatter: {
      title: string;
      excerpt: string;
    };
  };
};

const TagRoute: FC<Props> = props => {
  const posts = props.data.allMarkdownRemark.edges;
  const postLinks = posts.map(post => (
    <Link
      className="c-news-media"
      to={post.node.fields.slug}
      key={post.node.fields.slug}
    >
      <article className="c-news-media__inner">
        <div className="c-news-media__col">
          <p className="c-news-media__date">{post.node.frontmatter.date}</p>
          <h2 className="c-news-media__title">
            <span>{post.node.frontmatter.title}</span>
          </h2>
          <p className="c-news-media__description">{post.node.excerpt}</p>
        </div>
        <div className="c-news-media__col">
          <div className="c-news-media__image">
            {post.node.frontmatter.featuredimage ? (
              <PreviewCompatibleImage
                imageInfo={{
                  image: post.node.frontmatter.featuredimage,
                  alt: `featured image thumbnail for post ${post.node.frontmatter.title}`
                }}
              />
            ) : (
              <img src={DefaultImage} alt="天神皮ふ科" />
            )}
          </div>
        </div>
      </article>
    </Link>
  ));
  const tag = props.pageContext.tag;
  const title = props.data.site.siteMetadata.title;
  const totalCount = props.data.allMarkdownRemark.totalCount;
  const tagHeader = `${tag}`;

  return (
    <Layout>
      <Helmet title={`${tag} | ${title}`} />
      <div className="c-bread">
        <div className="c-bread__inner">
          <div className="c-bread__item">
            <Link to={"/"} className="c-bread__link">
              <div className="c-bread__text">トップ</div>
            </Link>
          </div>
          <div className="c-bread__item">
            <Link to={"/tags"} className="c-bread__link">
              <div className="c-bread__text">タグ</div>
            </Link>
          </div>
          <div className="c-bread__item">
            <div className="c-bread__text">{tagHeader}</div>
          </div>
        </div>
      </div>

      <section className="l-section-lv2 l-main-content-hero -medium-hero js-more-contents">
        <div className="l-main-content-hero__inner">
          <h2 className="c-pickup-card__title">
            <span className="c-yellow-maker">{tagHeader}</span>
          </h2>

          <div className="taglist">{postLinks}</div>
          <p>
            <Link to="/tags/">すべてのタグを見る</Link>
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default TagRoute;

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 200)
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "YYYY.MM.DD")
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
